.image2 {
    height: 100px;
    width: 100px;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
    backface-visibility: hidden;
}

.front, .back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
}

.back {
    transform: rotateY(180deg);
}

.flipContainer {
    perspective: 1000px;
    width: 200px; /* Ajustar según sea necesario */
    height: 200px; /* Ajustar según sea necesario */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flipper {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s ease-in-out;
    transform-style: preserve-3d;
}

.flipContainer:hover .flipper {
    transform: rotateY(180deg);
}

.text {
    margin-left: 10px;
    font-size: 13px;
    text-align: center;
    backface-visibility: hidden;
}

.text2 {
    text-align: center;
}

.position {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.backgroundd {
    background-image: url('/public/trama_cucharas_.png');
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 20px;
    padding-left: 20px;
}

.reKits {
    height: 100px;
    margin-bottom: 20px;
}

.kits {
    background-color: #071E18;
    color: white;
    padding: 10px 7px;
    border-radius: 20px;
    text-align: center;
    margin-top: 14px;
}

@media (max-width: 500px) {
    .backgroundd {
        padding-top: 40px;
        height: auto;
    }
    .kits {
        margin-top: 0px;
        align-items: center;
        justify-content: center;
    }
    .image2 {
        height: 100%;
    }
    .position {
        height: 70%;
    }
}
