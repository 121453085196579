.background{
    background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722372788/reZuma/Seccion_Rezuma-_Mesa_de_trabajo_1_mdsy0h.jpg');
    background-repeat: no-repeat;
    width: 100vw;
    height: 120vh;
    background-size: cover;
}

.two{
   background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722216307/reZuma/Seccion_Rezuma-02_zpphwv.jpg'); 
   width: 100vw;
   height: 55vh;
   background-size: cover;
   background-repeat: no-repeat;
}

.three{
   width: 100vw;
   height: 70vh;
   background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722372916/reZuma/Seccion_Rezuma--03_m39h3s.jpg');
   background-size: cover;
   background-repeat: no-repeat;
}
   


.threeMobile{
    width: 100vw;
   height: 40vh;
   background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722215725/reZuma/Seccion_Rezuma_mob_-06_p8m9ym.jpg');
   background-size: cover;
   background-repeat: no-repeat;
}

.backgroundMobile{
    width: 100vw;
    height: 100vh;
    background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722215709/reZuma/Seccion_Rezuma_mob_-04_mi45py.jpg');
    background-size: contain;
    background-repeat: no-repeat;
}

.twoMobile{
    width: 100vw;
    height: 60vh;
    background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722215716/reZuma/Seccion_Rezuma_mob_-05_ujjkb6.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: aqua;
}

.b{
    background-image: url('https://res.cloudinary.com/dkw8e06fo/image/upload/v1722382511/reZuma/Seccion_Rezuma__Mesa_de_trabajo_1_i49x9f.jpg');
    height: 200vh;
    background-size: contain;
}

.b2{
    width: 100vw;
    height: auto;
}

.b3{
    width: 100vw;
    height: auto;
}