.imagen{
    width: 100vw;
    height: 100vh;

}

.backGround{
    background-color: #E8E1D9 !important;
}
*{
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.buttonChatBot{
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 27px;
    right: 25px;
    cursor: pointer;
}


@media (max-width: 500px) {
    .imagen{
        width: 100%;
        height: 80vh;
    }
    
}

.imagen2{
    width: 100vw;
    height: 100vh;
    background-image: url('/src/assets/1.avif');
    background-size: cover;
    background-position: 30%;
    background-repeat: no-repeat;
}

.imagen1{
    width: 100vw;
    height:100vh;
    background-image: url('/src/assets/2.avif');
    background-size: cover;
    background-position: 30%;
    background-repeat: no-repeat;
    background-position: 50%;
}