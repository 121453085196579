.form {
    margin-top: 20px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    margin: 0 auto;
}

.inputGroup {
    margin-bottom: 20px;
}

.inputGroup label {
    display: block;
    margin-bottom: 10px;
    color: #333;
    font-size: 16px;
    font-weight: bold;
}

.inputGroup input,
.inputGroup textarea,
.inputGroup select {
    width: calc(100% - 30px); /* Resta el padding del ancho total */
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    outline: none;
    transition: border-color 0.3s ease;
}

.inputGroup input:focus,
.inputGroup textarea:focus,
.inputGroup select:focus {
    border-color: #4CAF50;
}

.inputGroup textarea {
    min-height: 120px;
    resize: vertical;
}

.button {
    padding: 15px 30px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    outline: none;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #45a049;
}

.imagePreview {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.imageContainer {
    position: relative;
}

.image {
    max-width: 100px;
    max-height: 100px;
    object-fit: cover;
    border-radius: 8px;
}

.removeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.3s ease;
}

.removeButton:hover {
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
}
