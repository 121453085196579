/* TraePedidos.module.css */
.pedidosContainer {
    padding: 20px;
  }
  
  .card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    background-color: #fff;
  }
  
  .card p {
    margin: 8px 0;
  }
  
  .numeroPedido {
    display: flex;
    justify-content: end;
  }