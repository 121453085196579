.productList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    padding: 20px;
}

.productCard {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    width: 100%;
    max-width: 300px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    overflow: hidden;
    box-sizing: border-box;
    justify-content: space-between;
}

.productCard:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.productCard.inactive {
    opacity: 0.5;
}

.productCard.inactive::after {
    content: 'Inactivo';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 0, 0, 0.7);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    font-weight: bold;
}

.label {
    align-self: flex-start;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

.input,
.textarea {
    width: 100%;
    margin-bottom: 1rem;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
}

.textarea {
    height: 100px;
}

.image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
}

.image:hover {
    transform: scale(1.1);
}

.title {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.description {
    margin: 0.5rem 0;
    font-size: 1rem;
    color: #666;
}

.price,
.stock {
    margin: 0.5rem 0;
    font-size: 1rem;
    font-weight: bold;
}

.button {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 0.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

@media (max-width: 768px) {
    .productList {
        flex-direction: column;
        align-items: center;
    }

    .productCard {
        width: 100%;
        max-width: 100%; /* Slightly reduces the width to avoid touching screen edges */
        margin-bottom: 1rem;
    }

    .image {
        max-height: 300px; /* Increase image height for better visibility on mobile */
    }
}

.imageContainer {
    display: flex;
    gap: 0.5rem;
    margin: 1rem 0;
    justify-content: center;
}

.imageContainer img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 5px;
    transition: transform 0.2s, border-color 0.2s;
}

.imageContainer img:hover {
    transform: scale(1.1);
}

.selectedImage {
    border-color: #007bff;
}

.selectedImagePreview {
    width: 100px;
    height: 100px;
    margin: 1rem 0;
    border: 2px solid #007bff;
    border-radius: 5px;
    object-fit: cover;
    display: block;
}

