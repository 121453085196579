.background{
    background-color: #071E18;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.logo{
    height: 90%;
}

.seguinos{
    color: white;
    width: 30%;
    text-align: end;
}

.fb{
    height: 40px;
    position: relative;
    bottom: 5px;
}

@media (max-width: 600px) {
    .seguinos{
       display: flex;
       align-items: center;
    }
}

.etiquetas{
    height: 50px;
}