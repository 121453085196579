.background {
    height: 65vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 30px; */
    margin-top: 10px;
    padding-right: 20px;
        padding-left: 20px;
}

.text {
    text-align: center;
    margin-bottom: 20px;
}

.image {
    width: 60%;
    height: 100%;
}

.carouselButton {
    background-color: #E8E1D9;
    color: #333;
    padding: 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    outline: none;
}

.carouselButton svg {
    font-size: 10px;
}

.carouselButton:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.slider {
    width: 80vw;
    height: 50%;
    /* background-color: aqua; */
    /* border-radius: 100%; */
}

.b {
    display: flex;
}

.kit {
    background-color: #071E18;
    color: white;
    padding: 10px 20px;
    border-radius: 50px;
    width: 200px;
    text-align: center;
    margin-top: 20px;
}

/* Estilos para el efecto flip card */
.flipCard {
    background-color: transparent;
    width: 100%;
    height: 100%;
    perspective: 1000px; /* Ajusta la perspectiva para el efecto 3D */
}

.flipCardInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.5s;
    transform-style: preserve-3d;
    border-radius: 10px; /* Añade borde redondeado */
}

.flipCard:hover .flipCardInner {
    transform: rotateY(180deg); /* Rota el contenedor al hacer hover */
}

.flipCardFront, .flipCardBack {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Añade borde redondeado */
}



.flipCardBack {
    background-image: url('/public/sopa_pp.png'); /* Imagen de fondo del reverso */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transform: rotateY(180deg);
    color: #333;
    font-size: 20px;
    height: 100%;
}

.resopa{
    height: 100px;
    align-self: center;
}

@media (max-width: 500px) {
    .background{
        height: 60vh;   
        margin-top: 10px;
        /* background-color: aqua;  */
        justify-content: space-around;
    }
    
}