/* General container styles */
.container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    font-family: 'Arial', sans-serif;
  }
  
  /* Title styles */
  .title {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
    text-align: center;
  }
  
  /* Soups list styles */
  .soupsList {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px;
    justify-content: center;
  }
  
  /* Individual soup item styles */
  .soupItem {
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .soupItem:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Soup image styles */
  .soupImage {
    width: 100%;
    height: 150px; /* Ajusta el tamaño de la imagen */
    object-fit: cover; /* Hace que la imagen se ajuste dentro del contenedor */
    border-radius: 10px;
  }
  
  /* Soup information styles */
  .soupInfo {
    margin-top: 10px;
  }
  
  .soupName {
    font-size: 18px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .selectButton {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .selectButton:hover {
    background-color: #218838;
  }
  
  /* Selected soups list styles */
  .selectedSoups {
    margin-top: 20px;
    text-align: center;
  }
  
  .subtitle {
    font-size: 24px;
    margin-bottom: 10px;
    color: #333;
  }
  
  /* Selected soup item styles */
  .selectedSoupItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .selectedSoupItem:hover {
    background-color: #f8f9fa;
  }
  
  .deselectButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .deselectButton:hover {
    background-color: #c82333;
  }
  
  .createKitButton {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .createKitButton:hover {
    background-color: #0056b3;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .soupsList {
      flex-direction: column;
      align-items: center;
    }
  
    .soupItem {
      width: 80%;
    }
  
    .selectedSoupItem {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  