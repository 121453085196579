.backgroundd{
    height: auto;
    width: 100%;
    background-color: #071E18;
    display: flex;  
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
  }
  
  .image{
    height: 300px;
    margin-right: 20px;
    padding-right: 50px;
  }
  
  .section{
    color: white;
    font-size: 18px;
  }
  
  .title{
    margin-bottom: 15px;
  }
  
  @media (max-width: 500px) {
    .backgroundd{
        height: 80vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;      
        /* justify-content: space-around; */
    }
  
    .image{
      height: 38%;
      margin-right: 0px;
      padding-right: 0px;
      margin-bottom: 20px;
    }
  
    .section{
      display: flex;
      font-size: 17px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      justify-content: space-around;
    }
  
    .text{
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      /* background-color: aqua; */
    }
  }
  
  .po{
    display: flex;
  }

  .solucion{
    padding-top: 20px;
  }