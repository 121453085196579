.background {
    background-color: #E8E1D9 !important;
    width: 100vw;
    min-height: 100vh;
    padding-bottom: 20px;
    text-align: center;
}

.text {
    text-align: center;
    padding-top: 50px;
}

.section {
    background-image: url('/public/trama_cucharas_.png');
    display: flex;
    justify-content: center;
    height: 60vh;
    width: 100vw;
    padding-top: 15%;
}

.resopa {
    align-self: center;
    max-width: 80%;
}

.productImage {
    width: 100%;
    height: auto; /* Ajusta la altura automáticamente */
    max-height: 80%; /* Establece una altura máxima */
    object-fit: cover; /* Cubre el área de la tarjeta sin distorsión */
    display: block;
    margin: 0 auto;
}

.card {
    background-color: #E8E1D9 !important;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    color: #071E18;
    border: none;
    text-align: center;
    padding-bottom: 20px;
    height: 100%; /* Asegura que la tarjeta tome toda la altura de su contenedor */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 15px; /* Bordes redondeados para las tarjetas */
    overflow: hidden; /* Asegura que el contenido no se salga de la tarjeta */
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-body {
    padding: 20px;
    flex-grow: 1; /* Asegura que el cuerpo crezca para llenar el espacio disponible */
}

.card-title {
    font-size: 1.2rem;
    color: #071E18;
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 5%;
}

.card-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    gap: 10px;
    text-decoration: none;
}

.button1 {
    border-radius: 40px;
    padding: 10px;
    background-color: #E8E1D9;
    border: black 1px solid;
    color: black;
    width: 45%;
    transition: background-color 0.3s ease;
}

.button1:hover {
    background-color: #d3c8bd;
    border: none;
}

.button2 {
    border-radius: 40px;
    background-color: #071E18;
    border: black 1px solid;
    color: white;
    width: 45%;
    transition: background-color 0.3s ease;
}

.button2:hover {
    background-color: #03403a;
}

.mainButton {
    width: 70vw;
    background-color: #071E18;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.mainButton:hover {
    background-color: #03403a;
}

@media (max-width: 600px) {
    .text {
        font-size: 100%;
    }
    .resopa {
        max-width: 50%;
    }
    .background {
        padding-top: 39px;
    }
    .section {
        padding-top: 15px;
        height: 30vh;
    }

}

.link{
    color: white;
}



  @media (max-width: 768px) {
    .customtoastcontainer {
     left: 0px; /* Ajusta la posición para pantallas más pequeñas */
    }
}