/* General Styles */
.backgroundd {
  height: 50vh;
  width: 100%;
  background-color: #071E18;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
}

/* Image Styling */
.image {
  height: 300px;
  margin-right: 20px;
  padding-right: 50px;
}

/* Section and Typography */
.section {
  color: white;
  font-size: 18px;
}

.title {
  margin-bottom: 15px;
}

/* Additional Elements */
.po {
  display: flex;
}

.text {
  font-size: 19px;
}

/* Responsive Design */
@media (max-width: 500px) {
  .backgroundd {
      height: 100vh;
      flex-direction: column;
  }

  .image {
      height: 38%;
      margin-right: 0;
      padding-right: 0;
  }

  .section {
      display: flex;
      font-size: 17px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
  }

  .text {
      text-align: center;
  }
}
