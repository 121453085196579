/* Estilo general */
.background {
    background-color: #E8E1D9 !important;
    width: 100vw;
    min-height: auto;
    padding-bottom: 20px; /* Añade espacio en la parte inferior */
}

.text {
    text-align: center;
    padding-top: 50px;
}

.section {
    background-image: url('/public/trama_cucharas_.png');
    display: flex;
    justify-content: center;
    height: 60vh;
    width: 100vw;
    padding-top: 15%;
}

.resopa {
    align-self: center;
    max-width: 80%;
}

.imageContainer {
    position: relative;
    overflow: hidden;
    perspective: 1000px; /* Añade perspectiva para el efecto 3D */
}

.productImage, .overlayImage {
    width: 70%;
    height: auto;
    padding: 10px; /* Añade espacio a los costados */
    display: block; /* Hace que la imagen se comporte como un bloque */
    margin: 0 auto; /* Centra la imagen horizontalmente */
}

.card {
    background-color: #E8E1D9 !important;
    border: none; /* Quita el borde */
    transition: transform 0.5s ease, box-shadow 0.3s ease; /* Suaviza la transición de transformación y sombra */
    color: #071E18;
    height: auto;
    padding-top: 20px;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Añade una sombra al pasar el mouse */
}

.card-img-top {
    height: auto; /* Ajusta la altura automáticamente */
}

.card-body {
    padding: 20px;
}

.card-title {
    font-size: 1.4rem;
    color: #071E18;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.card-text {
    font-size: 1rem;
    color: #555;
    margin-bottom: 15px;
    text-align: center;
}

.productName {
    text-align: center;
}

.buttonGroup {
    display: flex;
    justify-content: center;
    gap: 10px; /* Añade espacio entre los botones */
    text-decoration: none;
}

.button1 {
    border-radius: 40px;
    background-color: #E8E1D9;
    border: black 1px solid;
    color: black;
    width: 45%;
    transition: background-color 0.3s ease; /* Añade una transición para el color de fondo */
    text-decoration: none;
}

.button1:hover {
    background-color: #d3c8bd; /* Cambia el color de fondo al pasar el mouse */
    border: none;
}

.button2 {
    border-radius: 40px;
    background-color: #071E18;
    border: black 1px solid;
    width: 45%;
    transition: background-color 0.3s ease; /* Añade una transición para el color de fondo */
    text-decoration: none;
}

.button2:hover {
    background-color: #03403a; /* Cambia el color de fondo al pasar el mouse */
}

.mainButton {
    width: 70vw;
    background-color: #071E18;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;
}

.mainButton:hover {
    background-color: #03403a;
}

a {
    text-decoration: none;
}

.linkButton {
    color: black;
}

.linkButton2 {
    color: white;
}

.kitsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.cardContainer {
    flex: 1 0 21%; /* Ajusta el ancho de la tarjeta */
    max-width: 300px;
    margin: 8px; /* Añade un margen entre las tarjetas */
}

/* Estilo responsivo para pantallas medianas */
@media (max-width: 800px) {
    .cardContainer {
        flex: 1 0 100%; /* Asegura que solo se muestre una tarjeta por fila */
        max-width: 100%; /* Asegura que la tarjeta ocupe todo el ancho disponible */
    }
}

/* Estilo responsivo para pantallas pequeñas */
@media (max-width: 600px) {
    .text {
        font-size: 100%;
    }
    .resopa {
        max-width: 50%; /* Ajusta el tamaño de la imagen en pantallas pequeñas */
    }
    .background {
        padding-top: 39px;
    }
    .section {
        padding-top: 15px;
        height: 30vh;
    }
}

.container {
    text-decoration: none;
}

.price {
    font-size: 1rem;
    font-weight: bold;
    color: #071E18;
    margin-top: 10px;
    text-align: center;
}


@media (max-width: 768px) {
    .customtoastcontainer {
     left: 0px; /* Ajusta la posición para pantallas más pequeñas */
    }
}