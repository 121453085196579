/* Estilo para el fondo del contenedor */
.background {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8e1d9;
  padding-top: 10%;

}

/* Estilo para la tarjeta principal */
.card {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
 /*  background: white; */
  padding: 20px;
  box-shadow: none;
  transition: all 0.3s;
  border: none; /* Quita el borde */
}

/* Estilo al hacer scroll */
.scrolled {
  margin-top: 150px; /* Mueve la tarjeta hacia abajo cuando se hace scroll */
}

/* Estilo para la navegación */
.nav {
  width: 100%;
  color: #727272;
  text-transform: uppercase;
  padding: 20px;
  border-bottom: 2px solid #efefef;
  font-size: 12px;
}

/* Estilo para la flecha de retroceso */
.arrow {
  float: left;
  height: 15px;
  width: 15px;
  margin-right: 10px;
}

/* Estilo para la foto */
.photo {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo para la imagen */
.photo img {
  max-width: 80%;
  height: auto;
}

/* Estilo para la descripción */
.description {
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description h2 {
  color: #515151;
  text-transform: uppercase;
  font-weight: 500;
}

/* Estilo para el subtítulo */
.description h4 {
  color: #727272;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}

/* Estilo para el párrafo */
.description p {
  font-size: 14px;
  line-height: 20px;
  color: #727272;
  margin: 10px 0;
}

.description h1 {
  color: #515151;
  font-weight: 300;
  font-size: 30px;
  margin: 10px 0;
}

/* Estilo para el botón de sumar */
.suma {
  display: flex;
  justify-content: space-around;
  border-radius: 30px;
  margin: 20px 0;
  width: 50%;
  padding: 5px;
}

/* Estilo para el botón de agregar al carrito */
.but {
  background-color: #071E18;
  color: white;
  padding: 10px;
  width: 50%;
  text-align: center;
  border-radius: 30px;
  margin: 10px 0;
  cursor: pointer;
}

/* Estilo para los divs del menú */
.menuDiv {
  color: #515151;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  text-align: left;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.menuDiv:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* Estilo para el contenido colapsable */
.collapseContent {
  padding: 10px 20px;
  /* border: 1px solid #efefef; */
  border-top: none;
  font-size: 14px;
  color: #727272;
}

/* Estilos responsivos */
@media (max-width: 600px) {
  .background {
    padding-top: 0;
  }

  .card {
    height: auto;
    width: 100%;
    padding: 10px;
  }

  .description {
    padding: 10px;
  }

  .but {
    width: 100%;
  }

  .suma {
    width: 100%;
  }

  .menuDiv {
    font-size: 10px;
    width: 90%;
  }

  .description h2 {
    font-size: 16px;
  }

  .description p {
    font-size: 12px;
  }

  .description h1 {
    font-size: 20px;
  }

  .masMenos {
    text-align: center;
  }
}

/* Estilo para la sección de la tarjeta */
.section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@media (max-width: 600px) {
  .section {
    flex-direction: column;
  }

  .but {
    font-size: 70%;
    width: 70%;
  }

  .menuDiv {
    font-size: 5px;
    width: 90%;
  }

  .soupTitle {
    font-size: 15px;
  }

  .section2 {
    font-size: 100px;
  }
}

.but:hover {
  background-color: #03403a;
}

.s{
  overflow: hidden;

}

.conservar{
  width: 70%;
}