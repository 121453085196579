.background {
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  background-image: url('/public/trama_cucharas_.png');
  padding-bottom: 30px;
}

.section {
  display: flex;
  width:80%;
  height: auto;
  align-items: stretch;
  justify-content: space-between;
}

.kit {
  width: 33%;
  display: flex;
  height: auto;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  perspective: 1000px; /* Agrega perspectiva para el efecto 3D */
}

.imageContainer {
  position: relative;
  width:80%;
  padding-top:80%; /* Crea un cuadrado basado en el ancho */
  transform-style: preserve-3d;
  transition: transform 0.8s;
 /*  */
}

.frontImage, .backImage {
  position: absolute;
  top: 0;
  left: 30px;
  width:80%;
  height:80%;
  backface-visibility: hidden;
}

.frontImage {
  transform: rotateY(0deg);
}

.backImage {
  transform: rotateY(180deg);
}

.kit:hover .imageContainer {
  transform: rotateY(180deg);
}

.kit p {
  text-align: center;
  justify-content: center;
  position: relative;
  bottom: 5%; /* Ajusta la posición del texto */
}

.text3 {
  margin-bottom: 25%;
}

.kitButton {
  margin-top: auto;
  text-decoration: none;
  text-align: center;
  width: 70%;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 5px;
  background-color: #071E18;
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  text-align: center;
  text-decoration: none;
}

