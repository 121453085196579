.background {
    background-color: #f0f0f0;
    padding: 20px;
    min-height: 100vh;
  }
  
  .dashboard {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 20px;
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .buttonContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .dashboard {
      padding: 10px;
    }
  
    .buttonContainer {
      flex-direction: column;
      align-items: center;
    }
  
    .button {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  