.background {
    background-image: url('/public/Header_.png');
    background-size: cover;
    width: 100vw;
    height: 33vh; /* Altura original */
    background-repeat: no-repeat;
    position: fixed;
    z-index: 3;
}

.background.shrink {
    height: 15vh; /* Altura reducida en dispositivos pequeños */
}

.backgroundContent {
    width: 100%;
    height: 25vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
}

.title {
    filter: grayscale(100%) brightness(1000%);
    height: 90%;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 10px;
}

.title2 {
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carritoWrapper {
    position: relative;
}

.carrito {
    filter: grayscale(100%) brightness(1000%);
    height: 30px;
}

.cartCount {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 380px) {
    .background {
        background-image: url('/public/Header_.png');
        background-size: 100%;
        background-repeat: no-repeat;
        position: fixed;
        z-index: 3;
    }
    .background.shrink {
        height: 15vh; /* Altura reducida en dispositivos pequeños */
    }
    .backgroundContent {
        width: 100%;
        height: 15vh;
        display: flex;
        position: relative;
        bottom: 10px;
    }
    .title {
        height: 40%;
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media (max-width: 500px) {
    .background {
        background-image: url('/public/Header_.png');
        background-size: 100%;
        background-repeat: no-repeat;
        position: fixed;
        z-index: 3;
        top: 0;
    }
    .background.shrink {
        height: 14vh; /* Altura reducida en dispositivos medianos */
    }
    .backgroundContent {
        width: 100%;
        height: 14vh;
        display: flex;
        position: relative;
        bottom: 20px;
    }
    .title {
        height: 40%;
        padding-left: 50px;
        padding-right: 50px;
    }
}



.dropdownMenu {
    position: absolute;
    top: 40px; /* Ajustar para colocar el menú desplegable */
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);
    z-index: 10;
    width: 200px; /* Ajusta el ancho del menú */
}

.dropdownMenu.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}

.dropdownItem {
    padding: 15px 20px; /* Ajusta el tamaño del padding */
    color: #071E18;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    font-size: 1.1rem; /* Ajusta el tamaño de la fuente */
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}
