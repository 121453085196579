.cartContainer {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 90px;
  background-color: #E8E1D9;
}


.step-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
  padding-top: 50px;
  
}

.step {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
}

.complete {
  background-color: #035949;
  color: white;
}

.incomplete {
  background-color: #ca2302;
  color: white;
}

.footer {
  width: 100vw;
  position: fixed;
  bottom: 0;
}

.buttons {
  display: flex;
  justify-content: start;
}

.button2 {
  border-radius: 40px;
  padding: 10px;
  background-color: #E8E1D9;
  border: black 1px solid;
  color: black;
  width: 10vw;
  transition: background-color 0.3s ease;
  margin-right: 30px;
}

.button2:hover {
  background-color: #d3c8bd;
  border: none;
}

.button1 {
  border-radius: 40px;
  padding: 10px;
  background-color: #071E18;
  border: black 1px solid;
  color: white;
  width: 10vw;
  transition: background-color 0.3s ease;
}

.button1:hover {
  background-color: #03403a;
}

.total {
  text-align: start;
  padding-bottom: 15px;
}

@media (max-width: 1000px) {
 /*  .t{
    background-color: aqua;
    margin-left: 0%;
  } */
  .cartContainer {
    padding-top: 50px;
  }
  
  .buttons {
    align-items: center; /* Centrar los botones */
    justify-content: start;
    width: auto;
    justify-content: start;
    /* background-color: aqua; */
  }

  .button1 {
    width: 50%; /* Ajustar el ancho de los botones al 80% del contenedor en pantallas pequeñas */
    margin-bottom: 10px; /* Añadir espacio entre los botones */
    align-self: flex-start;
  }
  
  .button2 {
    width: 100%; /* Ajustar el ancho de los botones al 80% del contenedor en pantallas pequeñas */
    margin-bottom: 10px; /* Añadir espacio entre los botones */
    
  }
  .stepLabel {
    font-size: 12px;
   /*  margin-bottom: 50px; */
    /* background-color: aqua; */
  }

}

.color2{
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.imagen{
  width: 100%
}

.color3{
  border-radius: 90%;
  background-color: #ca2302;
}

.color{
  /* background-color: aqua; */
  text-align: start;
}


.step-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  padding-top: 50px;
}

.step {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.complete {
  background-color: #035949;
  color: white;
}

.incomplete {
  background-color: #ca2302;
  color: white;
}

.stepLabel {
  position: absolute;
  top: 60px; /* Ajusta la posición del texto debajo del círculo */
  width: 25vw; /* Ajusta el ancho según sea necesario */
  text-align: center;
  color: black; /* Cambia el color del texto si es necesario */
  padding-bottom: 20px;
}

.p{
  color: #03403a;
  font-weight: 600;
}

.sectionp{
  padding-top: 3%;
}

.error{
  background-color: #ca23027d;
  padding: 10px;
  border-radius: 8px;
  color: rgb(173, 2, 2);
}

@media (max-width: 768px) {
  .customtoastcontainer {
   left: 0px; /* Ajusta la posición para pantallas más pequeñas */
  }
  .customtoastcontainer2 {
    background-color: #E8E1D9;
   }
}

.customtoastcontainer2 {
  background-color: #E8E1D9;
 }
 
 .rere{
  width: 100%;
  text-align: center;
  /* background-color: #ca2302; */

 }

 @media (max-width: 768px) {
  .rere{
    
    width: 100%;
    
   }
}