.background {
    background-image: url('/public/Header_.png');
    width: 100vw;
    height: 10vh; /* Altura reducida */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position-y: 30%; /* Ajustar si es necesario */
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 3;
    transition: height 0.3s, background-position-y 0.3s; /* Transición suave */
}

.backgroundContent {
    width: 80%;
    height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-around;
    transition: transform 0.3s; /* Transición suave */
}

.title {
    filter: grayscale(100%) brightness(1000%);
    height: 75px; /* Altura reducida */
    transition: height 0.3s; /* Transición suave */
}

.carritoWrapper {
    position: relative;
}

.carrito {
    filter: grayscale(100%) brightness(1000%);
    height: 25px; /* Altura reducida */
    transition: height 0.3s; /* Transición suave */
}

.cartCount {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: red;
    color: white;
    border-radius: 50%;
    padding: 5px;
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdown {
    position: relative;
    cursor: pointer;
}

.dropdownMenu {
    position: absolute;
    top: 40px; /* Ajustar para colocar el menú desplegable */
    left: 0;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    transition: opacity 0.3s ease, transform 0.3s ease;
    opacity: 1;
    transform: translateY(0);
    z-index: 10;
    width: 200px; /* Ajusta el ancho del menú */
}

.dropdownMenu.hidden {
    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
}

.dropdownItem {
    padding: 15px 20px; /* Ajusta el tamaño del padding */
    color: #071E18;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    font-size: 1.1rem; /* Ajusta el tamaño de la fuente */
}

.dropdownItem:hover {
    background-color: #f0f0f0;
}
