.background{
    height: 50vh;
    background-color: #E8E1D9 !important; 
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    padding-top: 10px;
}

.wheel{
    height: 300px;
    margin-left: 20%;
}

.text2{
    font-size: 18px;
    text-align: center;
}

.circular{
    height: 48px;
    position: relative;
    top: 17px;
    margin-top: -20px;
    right: 10px;
}

.green{
    color: #90BFA9;
}

.text{
    font-size: 30px;
    text-align: center;
}

.sectionFooter{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #071E18;
    border-radius: 50px;
    width: 90%;
    text-align: center;
    margin-top: 20px;
}

.reFacil{
    height:30px;
    position: relative;
}

.bold{
    font-weight: 800;
}

.punto{
    position: relative;
    bottom: 12px;
    font-size: 40px;
    padding: 0px 5px;
}

.tirita{
    width: 100%;
    height: auto;
    position: relative;
    bottom: 10px;
}


@media (max-width: 500px) {
    .background{
        height: 60vh;   
        padding-top: 40px;
        justify-content: space-around;
        padding-right: 20px;
        padding-left: 20px;
    }

    .wheel{
        height: 250px;
        margin-left: 15%;
    }

    .text{
        font-size: 20px;
    }

    .circular{
       height: 40px;
       top: 11px;
    }

    .text2{
        font-size: 13px;
    }

    .sectionFooter{
        font-size: 10px;
    }
    .reFacil{
        height:25px;
        margin-bottom: 0px;
    }

    .punto{
        position: relative;
        bottom: 12px;
        font-size: 30px;
        padding: 0px 5px;
    }

    .descubri{
        padding-right: 2%;
        padding-left: 2%;
        text-align: center;
    }

}

.link{
    color: #071E18;;
}