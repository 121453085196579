.walletContainer {
    background-color: #E8E1D9; /* Light background color */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 105%;
    margin: 0 auto;
    position: relative;
    right: 4%;
  }
  
  .paymentMethodSelection {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  
  .paymentButton {
    background-color: #071E18; /* Dark green background color */
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .paymentButton:hover {
    background-color: #094526; /* Slightly lighter green for hover effect */
  }
  
  .activeButton {
    background-color: #0b3d34; /* Different shade for the active button */
  }
  
  .error {
    color: red;
    margin-top: 20px;
  }
  
  /* MercadoPago.module.css */

/* MercadoPago.module.css */

@keyframes smokeIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
    filter: blur(20px);
  }
  100% {
    opacity: 1;
    transform: scale(1);
    filter: blur(0);
  }
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  animation: smokeIn 1s ease-out;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  animation: smokeIn 1s ease-out;
}

.modal p {
  font-size: 1.5em;
  color: #333;
}

.modal button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  background-color: #071E18;
  color: white;
  cursor: pointer;
}

.modal button:hover {
  background-color: #03403a;
}

.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.mer{
  background-color: red;
}

@media (max-width: 768px) {
  .customtoastcontainer {
   left: 0px; /* Ajusta la posición para pantallas más pequeñas */
  }
  .customtoastcontainer2 {
    background-color: #E8E1D9;
   }
}

.customtoastcontainer2 {
  background-color: #E8E1D9;
 }