.container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    padding-top: 25%;
  }
  
  .nav {
    width: 100%;
    color: #727272;
    text-transform: uppercase;
    padding: 10px;
    border-bottom: 2px solid #efefef;
    font-size: 12px;
  }
  
  .arrow {
    float: left;
    height: 15px;
    width: 15px;
    margin-right: 10px;
  }
  
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  
  .photo {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .img {
    max-width: 100%;
    height: auto;
  }
  
  .description {
    width: 100%;
    text-align: center;
    padding: 10px;
  }
  
  .description h2 {
    color: #515151;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .description p {
    font-size: 14px;
    line-height: 20px;
    color: #727272;
    margin: 10px 0;
  }
  
  .description h1 {
    color: #515151;
    font-weight: 300;
    font-size: 24px;
    margin: 10px 0;
  }
  
  .addCart {
    background-color: #071E18;
    color: white;
    padding: 10px;
    width: 100%;
    text-align: center;
    border-radius: 30px;
    margin: 10px 0;
    cursor: pointer;
  }
  
  .menuDiv {
    color: #515151;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    padding: 10px;
    width: 100%;
    text-align: left;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .menuDiv:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  .collapseContent {
    padding: 10px 20px;
    border: 1px solid #efefef;
    border-top: none;
    font-size: 14px;
    color: #727272;
  }
  

  .backButton{
    z-index: 3;
  }

  .conservar{
    width: 110%;
  }

  .conservar2{
    width: 150%;
  }