.kitList {
  margin-top: 20px;
}

.kit {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.kit:hover {
  transform: scale(1.02);
}

.kitImage {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.kitName {
  font-size: 16px;
  color: #333;
  flex-grow: 1;
}

.deleteButton {
  background-color: #ff6b6b;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.deleteButton:hover {
  background-color: #ff3b3b;
}

.deleteButton:active {
  background-color: #e60000;
}
